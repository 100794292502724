import { PrjRow, PrjTitle, PrjList, PrjFeature, PrjCol, PrjListFull } from "../../../../src/data/projects/mdxComponents";
import * as React from 'react';
export default {
  PrjRow,
  PrjTitle,
  PrjList,
  PrjFeature,
  PrjCol,
  PrjListFull,
  React
};