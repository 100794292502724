import React from "react";
import { ImageDataLike } from "gatsby-plugin-image";
import { ProjectImage } from "../Projects/ProjectImage";

type prjRowProps = {
  images: { childImageSharp: ImageDataLike }[];
  alt: string;
};

export const PrjRow = ({ images, alt }: prjRowProps) => {
  return (
    <div className="prj__image-row">
      {images.map((image, index) => (
        <ProjectImage
          key={index}
          image={image.childImageSharp}
          alt={`${alt}-${index + 1}`}
        />
      ))}
    </div>
  );
};
