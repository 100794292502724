import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import React from "react";

type ProjectImageProps = {
  image: ImageDataLike;
  alt: string;
};

export const ProjectImage = ({ image, alt }: ProjectImageProps) => {
  const imageSet = getImage(image);

  return <>{imageSet && <GatsbyImage image={imageSet} alt={alt} />}</>;
};
