import React from "react";

export const PrjList: React.FC<{ feature?: boolean }> = ({
  children,
  feature,
}) => {
  return (
    <ul className={feature ? "prj__features" : "prj__list"}>{children}</ul>
  );
};
