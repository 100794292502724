import React from "react";

type trjGifProps = {
  src: {
    publicURL: string;
  };
  alt: string;
};

export const PrjGif = ({ src, alt }: trjGifProps) => {
  return (
    <div className="prj__gif-wrapper">
      <img src={src.publicURL} alt={alt} loading="lazy" className="prj_gif" />
    </div>
  );
};
