import { PrjTitle, ProjectImage, PrjCol, PrjFeature, PrjList, PrjText } from "../../../../src/data/projects/mdxComponents";
import * as React from 'react';
export default {
  PrjTitle,
  ProjectImage,
  PrjCol,
  PrjFeature,
  PrjList,
  PrjText,
  React
};