import React from "react";

type prjFeatureProps = {
  feature: string;
};

export const PrjFeature = ({ feature }: prjFeatureProps) => {
  return (
    <li className="prj__feature">
      <span className="prj__feature--icon">
        <svg width="15" height="15" fill="none">
          <mask id="prjFeature" fill="#fff">
            <path d="M0 3a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z" />
          </mask>
          <path
            d="M0 3a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Z"
            className="prj__feature--icon-light"
          />
          <path
            d="M0 0h15H0Zm15 15H0h15ZM3 15a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5c-.552 0-1 1.343-1 3v9c0 1.657.448 3 1 3ZM15 0v15V0Z"
            className="prj__feature--icon-dark"
            mask="url(#prjFeature)"
          />
        </svg>
      </span>
      <span className="prj__feature--text">{feature}</span>
    </li>
  );
};
